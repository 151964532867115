<template>
  <div class="bread">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item v-for="(item, index) in Breadcrumb" :key="index">{{
        item.name
      }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: ["Breadcrumb"],
};
</script>

<style lang="scss" scoped>
// 面包屑
.bread {
  height: 30px;
  ::v-deep .el-breadcrumb__inner {
    font-weight: bold;
  }
}
</style>
